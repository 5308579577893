import TYPES from '@/types';

import Vue from 'vue';
import { v4 } from 'uuid';

// Application
import CreateTransactionCommand
  from '@/modules/my-investment/allianz/transaction/application/commands/create-transaction-command';

import SearchTransactionTypesQuery
  from '@/modules/my-investment/allianz/transaction-type/application/queries/search-transaction-types-query';

import SearchCurrencyTypesQuery
  from '@/modules/my-investment/allianz/currency-type/application/queries/search-currency-types-query';

import SearchTransactionStatusQuery
  from '@/modules/my-investment/allianz/transaction-status/application/queries/search-transaction-status-query';

import GetAllianzDepositOperationDatesQuery
  from '@/modules/my-investment/allianz/allianz-operation-dates/application/queries/get-allianz-deposit-operation-dates-query';
import InvestorGoalScheduledDistributionWealthFlexibleCommand
  from '@/modules/flagship/investor-goal-scheduled-distribution/sowos-wealth/flexible/application/commands/investor-goal-scheduled-distribution-wealth-flexible-command';

// Domain
import { WealthFlexibleDepositStateManager } from '@/modules/my-investment/allianz/transaction/domain/state/wealth-flexible-deposit-state-manager';
import { StateManager as AllianzAccountStateManager } from '@/modules/my-investment/allianz/allianz-account/domain/state/state-manager';
import {
  CreateTransactionDto,
} from '@/modules/my-investment/allianz/transaction/domain/dtos/create-transaction-dto';
import Inject from '@/modules/shared/domain/di/inject';
import { DateFormatter } from '@/modules/shared/domain/date-formatters';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class AllianzDepositFlexibleConfirmationViewModel {
  @Inject(TYPES.CREATE_ALLIANZ_TRANSACTIONS_COMMAND)
  private readonly create_transaction_command!: CreateTransactionCommand

  @Inject(TYPES.GET_ALLIANZ_DEPOSIT_OPERATION_DATES_QUERY)
  private readonly get_allianz_deposit_dates_query!: GetAllianzDepositOperationDatesQuery;

  @Inject(TYPES.SEARCH_TRANSACTION_STATUS_QUERY)
  private readonly search_transaction_status_query!: SearchTransactionStatusQuery;

  @Inject(TYPES.SEARCH_TRANSACTION_TYPES_QUERY)
  private readonly search_transaction_types_query!: SearchTransactionTypesQuery;

  @Inject(TYPES.SEARCH_CURRENCY_TYPES_QUERY)
  private readonly search_currency_types_query!: SearchCurrencyTypesQuery;

  @Inject(TYPES.INVESTOR_GOAL_SCHEDULED_DISTRIBUTION_WEALTH_FLEXIBLE_COMMAND)
  // eslint-disable-next-line max-len
  private readonly investor_goal_scheduled_distribution_wealth_flexible_command!: InvestorGoalScheduledDistributionWealthFlexibleCommand;

  @Inject(TYPES.ALLIANZ_ACCOUNT_STATE_MANAGER)
  readonly allianz_account_state_manager!: AllianzAccountStateManager;

  @Inject(TYPES.WEALTH_FLEXIBLE_DEPOSIT_STATE_MANAGER)
  readonly wealth_flexible_deposit_state_manager!: WealthFlexibleDepositStateManager;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  @Inject(TYPES.DATE_FORMATTER)
  readonly date_formatter!: DateFormatter;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  private readonly view: Vue;

  readonly i18n_namespace = 'components.allianz-dashboard.deposits.flexible_deposit_confirmation';

  private transaction_status_id = '';

  private transaction_type_id = '';

  private currency_type_id = '';

  is_loading = false;

  number = 2;

  id = '';

  public constructor(view: Vue) {
    this.view = view;
    this.id = v4();
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  setOperationDates = async (transaction_type_id: string) => {
    const op_dates = await this.get_allianz_deposit_dates_query.execute(transaction_type_id);
    const accreditation_date = this.date_formatter.formatDate(op_dates.accreditation_operation_date, 'DD [de] MMMM  [del] YYYY');
    const estimated_reflected_date = this.date_formatter.formatDate(op_dates.estimated_reflected_date, 'DD [de] MMMM [del] YYYY');
    const estimated_collection_date = this.date_formatter.formatDate(op_dates.estimated_collection_date, 'DD [de] MMMM [del] YYYY');
    const application_date = this.date_formatter.formatDate(op_dates.application_operation_date, 'DD [de] MMMM [del] YYYY');
    this.wealth_flexible_deposit_state_manager.patch({
      application_date,
      accreditation_date,
      estimated_collection_date,
      estimated_reflected_date,
    });
  }

  confirmOperation = async () => {
    this.is_loading = true;
    try {
      const amount = parseFloat(
        this.wealth_flexible_deposit_state_manager.state.deposit.amount.replaceAll(/[^0-9]/g, ''),
      );
      const allianz_transaction: CreateTransactionDto = {
        id: this.id,
        customer_id: this.allianz_account_state_manager.state.account.customer_id,
        allianz_account_id: this.allianz_account_state_manager.state.account.id,
        policy_number: this.allianz_account_state_manager.state.account.policy_number,
        transaction_status_id: this.transaction_status_id,
        transaction_type_id: this.transaction_type_id,
        currency_type_id: this.currency_type_id,
        amount,
        source_fund_code: null,
        source_fund_name: null,
        destination_fund_code: this.wealth_flexible_deposit_state_manager.state.deposit
          .destination_account.found_code,
        destination_fund_name: this.wealth_flexible_deposit_state_manager.state.deposit
          .destination_account.found_name,
      };
      await this.create_transaction_command.execute(allianz_transaction);
      const transaction_event = new CustomEvent('allianz.create.pending.transaction', { detail: { ...allianz_transaction } });
      window.dispatchEvent(transaction_event);
      return true;
    } catch {
      this.messageNotifier.showErrorNotification(this.translate('error_on_create_transaction'));
      return false;
    } finally {
      this.is_loading = false;
    }
  }

  saveDistributionAmongLinkedGoals = async () => {
    try {
      if (this.wealth_flexible_deposit_state_manager.state.goal_distribution.total_contribution) {
        await this.investor_goal_scheduled_distribution_wealth_flexible_command.execute(
          this.wealth_flexible_deposit_state_manager.state.goal_distribution,
        );
      }
      return true;
    } catch {
      this.messageNotifier.showErrorNotification(this.translate('errors.investor_goal_scheduled_distribution_wealth_flexible_command'));
      return false;
    }
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = async () => {
    const saved = await this.confirmOperation();
    if (saved) {
      const distributionSaved = await this.saveDistributionAmongLinkedGoals();
      if (distributionSaved) {
        this.view.$emit('nextStep');
      }
    }
  }

  initialize = async () => {
    this.is_loading = true;
    const transaction_types = await this.search_transaction_types_query.execute();
    const transaction_status = await this.search_transaction_status_query.execute();
    const currency_types = await this.search_currency_types_query.execute();
    const deposit_type = transaction_types.find((transaction_type) => transaction_type.name === 'Deposit');
    if (deposit_type) {
      this.transaction_type_id = deposit_type.id;
    }
    const status_pending = transaction_status.find((status) => status.name === 'Pending');
    if (status_pending) {
      this.transaction_status_id = status_pending.id;
    }
    const currency_pesos = currency_types.find((currency_type) => currency_type.name === 'Mexican Peso');
    if (currency_pesos) {
      this.currency_type_id = currency_pesos.id;
    }
    await this.setOperationDates(this.transaction_type_id);
    this.is_loading = false;
  }
}
