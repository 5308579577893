



























































































































































import {
  Component, Vue,
} from 'vue-property-decorator';
import AllianzDepositFlexibleConfirmationViewModel
  from '@/vue-app/view-models/allianz-dashboard/deposits/flexible_deposits/allianz-deposit-flexible-confirmation-view-model';

@Component({
  name: 'FlexibleDepositConfirmation',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class FlexibleDepositConfirmation extends Vue {
  deposit_flexible_confirmation_view_model = Vue.observable(
    new AllianzDepositFlexibleConfirmationViewModel(this),
  );

  mounted() {
    this.deposit_flexible_confirmation_view_model.initialize();
  }
}

